module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bring and ring","short_name":"Bring and ring","start_url":"/","background_color":"##FAF8F7","theme_color":"##4DCB8B","display":"standalone","icon":"src/content/images/logos/logo-white.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-162115016-1"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1140,"quality":90,"linkImagesToOriginal":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://bringandring.de"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
